import { acceptHMRUpdate } from 'pinia'

interface ShopByBudgetParams {
  vehiclePrice: number
  c_range: number
  real_term: number
  real_down_payment: number
  trade_in_value: number
  mode: 'monthly_payment' | 'vehicle_budget'
  real_payment_amount: number
  zip?: string
}

const defaultParams: ShopByBudgetParams = {
  vehiclePrice: 20000,
  real_term: 72,
  c_range: 690,
  real_down_payment: 0,
  trade_in_value: 0,
  zip: '',
  real_payment_amount: 350,
  mode: 'monthly_payment',
}

export const useShopByBudgetStore = defineStore('ShopByBudget', () => {
  const expires = new Date(Date.now() + 86400 * 1000 * 30) // Expires in 30 day
  const storeData = useCookie<ShopByBudgetParams>('shop-by-budget-params', {
    expires,
  })

  if (!storeData.value || Object.keys(storeData.value).length === 0)
    storeData.value = defaultParams

  const shopByBudgetFinancingParams = computed(() => {
    return {
      c_range: storeData.value.c_range,
      real_term: storeData.value.real_term,
      real_down_payment: storeData.value.real_down_payment,
      trade_in_value: storeData.value.trade_in_value,
    }
  })

  const shopByBudgetParams = computed(() => storeData.value)
  const setShopByBudgetParams = (newParams: ShopByBudgetParams) => {
    storeData.value = newParams
  }

  return {
    shopByBudgetParams,
    setShopByBudgetParams,
    shopByBudgetFinancingParams,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useShopByBudgetStore, import.meta.hot))
